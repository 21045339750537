const getPriceOfCourse = (courseDetails, currency, old = false) => {
  if (old) {
    switch (currency) {
      case "USD":
        return courseDetails.oldPriceUSD;
      case "AED":
        return courseDetails.oldPriceAED;
      default:
        return courseDetails.oldPriceINR;
    }
  }
  switch (currency) {
    case "USD":
      return courseDetails?.priceUSD;
    case "AED":
      return courseDetails?.priceAED;
    default:
      return courseDetails?.priceINR;
  }
};
export { getPriceOfCourse };
