import React, { useRef, useEffect, useState } from "react";
import gsap from "gsap";
import axios from "axios";
import PrimaryButton from "../buttons/PrimaryButton";
import { useDispatch, useSelector } from "react-redux";
import moengageEvent from "../../helpers/MoengageEventTracking";
import {
  enterOTPAttributes,
  requestOTPAttributes,
  resendOTPAttributes,
  dismissOTPAttributes,
  loginFieldFillAttributes,
  loginFieldFilledAttributes,
} from "../../helpers/MoengageAttributeCreators";
import {
  sendOtp,
  setFreeLoginModalOpen,
  setFreeLogin,
  loginWithOtp,
  changeNumber,
  openLogin,
} from "../../store/actions/rootActions";
import { debounce } from "debounce";
// import { mixpanelAddAlias } from "../../helpers/mixpanelAddUser";
import { setValidCountryCode } from "../../store/actions/authActions";
import { AuthHook } from "../../hooks/ErrorHandlerHook";

function FreeLogin() {
  // ! Redux
  const loginModalOpen = useSelector((state) => state.auth.loginModalOpen);
  const authOtpRequested = useSelector((state) => state.auth.authOtpRequested);
  const tempCountryCode = useSelector((state) => state.auth.tempCountryCode);
  const tempPhoneNumber = useSelector((state) => state.auth.tempPhoneNumber);
  const tempEmail = useSelector((state) => state.auth.tempEmail);
  const utmObject = useSelector((state) => state.utility.utmObject);
  const authOtpRequestBody = useSelector(
    (state) => state.auth.authOtpRequestBody
  );

  const triggeredBy = useSelector((state) => state.auth.triggeredBy);
  const validCountryCodes = useSelector(
    (state) => state.auth.validCountrycodes
  );

  const dispatch = useDispatch();
  // ! Gsap
  const modalRef = useRef(null);
  const modalWrapperRef = useRef(null);
  const tweenRef = useRef(null);
  const opacityRef = useRef(null);
  const [errorHandler] = AuthHook();
  // ! Resend otp timer
  const [ticker, setTicker] = useState(15);
  useEffect(() => {
    modalRef.current.style.display = "flex";
    modalWrapperRef.current.style.display = "flex";
    tweenRef.current = gsap.fromTo(
      modalRef.current,
      { y: `-${window.innerHeight}`, autoAlpha: 1 },
      {
        y: `-${window.innerHeight * 0.1}`,
        autoAlpha: 1,
        duration: 0.6,
        ease: "back",
      }
    );
    opacityRef.current = gsap.fromTo(
      modalWrapperRef.current,
      { autoAlpha: 0 },
      { autoAlpha: 1, duration: 0.6 }
    );
  }, []);

  useEffect(() => {
    if (ticker === 15) {
      let count = 15;
      let resendTimeout = setInterval(() => {
        setTicker((ticker) => ticker - 1);
        count--;
        if (count < 1) clearInterval(resendTimeout);
      }, 1000);
    }
  }, [ticker]);
  const flowIdGenerator = () => {
    switch (window.location.pathname) {
      case "/book-a-trial/":
        return 1;
      case "/book-a-workshop/":
        return 2;
      case "/live-class/":
        return 3;
      default:
        return 5;
    }
  };
  // ! local states for the input fields
  const [countryCode, setCountryCode] = useState(tempCountryCode || "+91");
  const [phoneNumber, setPhoneNumber] = useState(tempPhoneNumber || "");
  const [email, setEmail] = useState(tempEmail || "");
  const [OTP, setOTP] = useState("");
  // ! State to manage the phone validation tooltip
  const [tooltipClass, setTooltipClass] = useState("phone-validation-tooltip");
  const [tooltipText, setTooltipText] = useState(<></>);
  // ! Regex
  let numberRegex = new RegExp(/^[0-9]*$/);
  let countryCodeRegex = new RegExp(/^\+[0-9]*$/);
  // ! Phone number validation // Replace with a logic to support all countries
  const phoneNumberLengthValidation = (number) => {
    switch (countryCode) {
      case "+91":
        return 10;
      case "+1":
        return 10;
      // case "+971":
      //   return 7;
      // case "+974":
      //   return 7;
      // case "+966":
      //   return 9;
      default:
        return number.length;
    }
  };
  // ! useeffect for phone number onchange
  useEffect(() => {
    if (phoneNumber[0] === "0") {
      setPhoneNumber((phoneNumber) => {
        let numberArray = phoneNumber.split("");
        numberArray.shift();
        return numberArray.join("");
      });
      setTooltipClass((tooltipClass) => "phone-validation-tooltip visible");
      setTooltipText(
        <>
          First digit
          <br />
          cannot be 0
        </>
      );
    }
    if (phoneNumber.length > phoneNumberLengthValidation(phoneNumber)) {
      setPhoneNumber((phoneNumber) => {
        phoneNumber = phoneNumber.split("");
        phoneNumber.pop();
        return phoneNumber.join("");
      });
    }
  }, [phoneNumber]);
  // ! toggle box
  const toggleLoginBox = () => {
    // setTimeout(() => {
    //   dispatch(openLogin());
    // }, 600);
    opacityRef.current.reverse();
    tweenRef.current.reverse();
  };
  // ! triggered by handler
  const triggeredByHandler = () => {};
  // ! checking OTP
  const checkOtpHandle = () => {
    moengageEvent(
      "Enter_Otp",
      enterOTPAttributes(
        countryCode === "+91" ? 3 : 2,
        "Enter OTP",
        flowIdGenerator(),
        countryCode === "+91" ? 1 : 2
      )
    );
    let userUpdateObject = null;
    if (utmObject?.utm_source) {
      userUpdateObject = {
        facebook_lead: {
          user_agent: window?.navigator?.userAgent || "",
          fb_click_id: window?.localStorage?.fbc || "",
          google_click_id: window?.localStorage?.gc || "",
          utm_campaign: utmObject?.utm_campaign || "",
          utm_content: utmObject?.utm_content || "",
          utm_medium: utmObject?.utm_medium || "",
          utm_source: utmObject?.utm_source || "",
          source: window.location.pathname,
        },
      };
    }
    dispatch(
      loginWithOtp(
        authOtpRequestBody,
        OTP,
        countryCode,
        phoneNumber,
        true,
        toggleLoginBox,
        triggeredByHandler,
        false,
        userUpdateObject
      )
    );
  };
  const debouncedResendOtp = () => {
    moengageEvent(
      "Resend_Otp",
      resendOTPAttributes(flowIdGenerator(), countryCode === "+91" ? 1 : 2)
    );
    if (validCountryCodes.includes(countryCode.slice(1))) {
      let body = { phone_no: `${countryCode}-${phoneNumber}` };
      if (window.location.pathname.includes("live")) {
        body.context = "join_class";
      }
      dispatch(sendOtp(body));
      setTicker(15);
    } else {
      let body = { email: email };
      if (window.location.pathname.includes("live")) {
        body.context = "join_class";
      }
      dispatch(sendOtp({ email: email }));
      setTicker(15);
    }
  };
  return (
    <div className="global-modal-wrapper" ref={modalWrapperRef}>
      {" "}
      <div className="auth-modal auth-login" ref={modalRef}>
        {authOtpRequested ? (
          <>
            <h1 className="auth-modal__header" style={{ paddingTop: "3rem" }}>
              {authOtpRequestBody.phone_no ? "Verify Number" : "Verify Email"}
            </h1>
            <input
              className="auth-modal__input"
              placeholder="Enter OTP"
              onChange={(ev) => setOTP(ev.target.value)}
              onFocus={() => {
                moengageEvent(
                  "Login_Field_Fill",
                  loginFieldFillAttributes(
                    10,
                    "Enter OTP",
                    "",
                    flowIdGenerator()
                  )
                );
              }}
              onBlur={() => {
                moengageEvent(
                  "Login_Field_Filled",
                  loginFieldFilledAttributes(10, "Enter OTP", flowIdGenerator())
                );
              }}
            />
            <PrimaryButton
              version="version-1"
              buttonText="Verify OTP"
              clickHandle={checkOtpHandle}
            />
            <span className="auth-modal__resend-otp">
              Didn’t receive an OTP?{" "}
              <button
                className={
                  ticker === 0
                    ? "auth-modal__alternate-button"
                    : "auth-modal__alternate-button inactive"
                }
                onClick={debouncedResendOtp}
              >
                Resend {ticker === 0 ? null : <span>{ticker}</span>}
              </button>
            </span>
            <button
              className="auth-modal__change-number auth-modal__alternate-button"
              onClick={() => dispatch(changeNumber())}
            >
              <img
                src={`${process.env.NEXT_PUBLIC_ALL_ASSETS_API}leftArrowOrange.svg`}
                alt=""
              />{" "}
              {authOtpRequestBody.phone_no ? "Change Number" : "Change Email"}
            </button>
          </>
        ) : (
          <>
            <h1 className="auth-modal__header">Sign in to Spark Studio</h1>
            <form className="contact">
              <div className="phone-number">
                <input
                  type="text"
                  placeholder="+91"
                  className="auth-modal__input"
                  value={countryCode}
                  onChange={(ev) => {
                    if (countryCodeRegex.test(ev.target.value))
                      setCountryCode(ev.target.value);
                  }}
                  onFocus={() => {
                    moengageEvent(
                      "Login_Field_Fill",
                      loginFieldFillAttributes(
                        4,
                        "Country Code",
                        "+91",
                        flowIdGenerator()
                      )
                    );
                  }}
                  onBlur={() => {
                    moengageEvent(
                      "Login_Field_Filled",
                      loginFieldFilledAttributes(
                        4,
                        "Country Code",
                        flowIdGenerator()
                      )
                    );
                  }}
                />
                <input
                  type="text"
                  placeholder="Phone Number"
                  className="auth-modal__input"
                  value={phoneNumber}
                  onChange={(ev) => {
                    if (numberRegex.test(ev.target.value)) {
                      if (ev.target.value === "0" && phoneNumber.length === 0) {
                        setPhoneNumber((phoneNumber) => {
                          let numberArray = phoneNumber.split("");
                          numberArray.shift();
                          return numberArray.join("");
                        });
                      } else {
                        setPhoneNumber(ev.target.value);
                      }
                    }
                  }}
                  onKeyPress={(ev) => {
                    if (ev.code === "Backspace") {
                      setPhoneNumber(ev.target.value);
                    }
                    if (ev.code === "Enter") {
                      if (
                        phoneNumber.length ===
                        phoneNumberLengthValidation(phoneNumber)
                      ) {
                        //   handleSubmit();
                      }
                    }
                  }}
                  onFocus={() => {
                    moengageEvent(
                      "Login_Field_Fill",
                      loginFieldFillAttributes(
                        3,
                        "Phone Number",
                        "",
                        flowIdGenerator()
                      )
                    );
                    setTooltipClass("phone-validation-tooltip");
                  }}
                  onBlur={(ev) => {
                    moengageEvent(
                      "Login_Field_Filled",
                      loginFieldFilledAttributes(
                        3,
                        "Phone Number",
                        flowIdGenerator()
                      )
                    );
                    if (
                      phoneNumber.length !==
                      phoneNumberLengthValidation(phoneNumber)
                    ) {
                      setTooltipClass("phone-validation-tooltip visible");
                      setTooltipText(
                        <p>
                          Please enter
                          <br />
                          {phoneNumberLengthValidation(countryCode) || 10}{" "}
                          digits
                        </p>
                      );
                    } else if (phoneNumber[0] === "0") {
                      setTooltipClass(
                        (tooltipClass) => "phone-validation-tooltip visible"
                      );
                      setTooltipText((tooltipText) => {
                        return (
                          <>
                            First digit
                            <br />
                            cannot be 0
                          </>
                        );
                      });
                    } else {
                      setTooltipClass("phone-validation-tooltip");
                    }
                  }}
                  required
                />
              </div>
              {!validCountryCodes.includes(countryCode.slice(1)) ? (
                <input
                  type="text"
                  className="auth-modal__input"
                  placeholder="Email"
                  value={email}
                  onChange={(ev) => setEmail(ev.target.value)}
                  onFocus={() => {
                    moengageEvent(
                      "Login_Field_Fill",
                      loginFieldFillAttributes(
                        2,
                        "Email",
                        "",
                        flowIdGenerator()
                      )
                    );
                  }}
                  onBlur={() => {
                    moengageEvent(
                      "Login_Field_Filled",
                      loginFieldFilledAttributes(2, "Email", flowIdGenerator())
                    );
                  }}
                />
              ) : null}

              <div className={tooltipClass}>
                <span>
                  Please enter
                  <br />
                  {phoneNumberLengthValidation(countryCode) || 10} digits
                </span>
              </div>
            </form>
            <PrimaryButton
              buttonText="Let's Go"
              version="version-1"
              clickHandle={() => {
                if (validCountryCodes.includes(countryCode.slice(1))) {
                  let body = { phone_no: `${countryCode}-${phoneNumber}` };
                  if (window.location.pathname.includes("live")) {
                    body.context = "join_class";
                  }
                  dispatch(
                    setFreeLogin(
                      `${countryCode}-${phoneNumber}`,
                      "",
                      body,
                      () => {},
                      validCountryCodes.includes(countryCode.slice(1))
                    )
                  );
                  //   dispatch(sendOtp(body));
                } else {
                  let body = { email: email };
                  if (window.location.pathname.includes("live")) {
                    body.context = "join_class";
                  }
                  dispatch(
                    setFreeLogin(`${countryCode}-${phoneNumber}`, email, body)
                  );
                  //   dispatch(sendOtp(body));
                }
              }}
            />
          </>
        )}
        <img
          src={`${process.env.NEXT_PUBLIC_ALL_ASSETS_API}sparkLogo.png`}
          alt=""
          className="auth-modal__logo"
        />
        <img
          src={`${process.env.NEXT_PUBLIC_ALL_ASSETS_API}cross.svg`}
          alt=""
          className="auth-modal__close"
          onClick={() => {
            moengageEvent(
              "Dismiss_Otp",
              dismissOTPAttributes(
                flowIdGenerator(),
                countryCode === "+91" ? 1 : 2
              )
            );
            setTimeout(() => {
              dispatch(setFreeLoginModalOpen());
            }, 600);
            opacityRef.current.reverse();
            tweenRef.current.reverse();
          }}
        />
      </div>
    </div>
  );
}

export default FreeLogin;
