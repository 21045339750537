const colors = {
  Communication: { light: "#EDFCFF", dark: "#83DEF0" },
  "Visual Arts": { light: "#DCCCFF", dark: "#8469C0" },
  Music: { light: "#FFEDC8", dark: "#FFC142" },
  Workshops: { light: "#EDFCFF", dark: "#83DEF0" },
};
const courseLevelCount = (displayName) => {
  switch (displayName.toLowerCase()) {
    case "guitar":
      return 2;
    case "western vocals":
      return 2;
    case "stop motion animation":
      return 2;
    case "art":
      return 2;
    case "public speaking":
      return 2;
    default:
      return 1;
  }
};
const courseDetailsParser = (course) => {
  return {
    courseId: course.identifier,
    rating: course.rating,
    urlSlug: course.url_slug,
    gamesCount: course.games_count,
    courseStatus: course.is_live ? "ACTIVE" : "UPCOMING",
    courseName: course.name,
    displayName: course.display_name,
    vertical: course.category_name,
    courseLevel: course.level,
    coursePlan: course.course_plan,
    numberOfClasses: course.num_classes,
    sessionDuration: course.session_duration_minutes,
    priceINR: Number(course.price_inr.split(".")[0]),
    priceUSD: Number(course.price_usd.split(".")[0]),
    priceAED: Number(course.price_aed.split(".")[0]),
    oldPriceINR: Number(course?.price_inr_old?.split(".")[0]),
    oldPriceUSD: Number(course?.price_usd_old?.split(".")[0]),
    oldPriceAED: Number(course?.price_aed_old?.split(".")[0]),
    minAge: course.min_age,
    maxAge: course.max_age,
    classRatio: course.class_size,
    showOutside: course.show_outside,
    showOutsideMinAge: course.show_outside_min_age,
    showOutsideMaxAge: course.show_outside_max_age,
    courseType: "Regular",
    coursePriority: course.priority,
    verticalThemeColorLight: colors[course.category_name].light,
    verticalThemeColorDark: colors[course.category_name].dark,
    courseLiner: course.course_one_liner,
    courseContent: course.pitch.split(".")[0],
    courseCurrency: "INR",
    isHidden: course.is_hidden,
    courseLevelCount: courseLevelCount(course.display_name),
    courseTags: course.tags,
    numberOfHomeActivities: course.number_of_home_activities,
    classSize: Number(course.class_size),
    courseUsp: course.usp_of_course,
    fullDescription: course.full_description,
    pitch: course.pitch,
    enumPitch: course.enum_pitch,
    shortPitch: course.short_pitch,
    socialProof: course.social_proof,
    curriculumLiner: course.curriculum_liner,
    curriculumOutcomes: course.curriculum_outcomes,
    curriculumSessions: course.curriculum_sessions,
    showcaseSectionHeader: course.showcase_section_header,
    showcaseSectionContent: course.showcase_section_content,
    showcaseData: course.showcase_data,
    testimonialData: course.testimonial_data,
    expertDetails: course.expert_details ?? null,
    primaryCard: course.is_primary,
    basePriceINR: course.base_price_inr,
    basePriceUSD: course.base_price_usd,
    basePriceAED: course.Base_price_aed,
    discountedPriceINR: course?.discounted_price_inr ?? 0,
    discountedPriceUSD: course?.discounted_price_usd ?? 0,
    discountedPriceAED: course?.discounted_price_aed ?? 0,

    // ! moengage ids
    moHomepageCardId: course.homepage_card_id,
    moAllCoursesCardId: course.all_courses_card_id,
    moAddToCartCardId: course.add_to_cart_card_id,
  };
};
export { courseDetailsParser };
