const checkoutActivityGtm = (
  eventName,
  currency,
  courseIds,
  category,
  displayName,
  amount,
  order_id = ""
) => {
  let localAmount = +amount;
  return {
    event: eventName,
    currency: currency,
    content_ids: courseIds,
    content_category: category,
    content_name: displayName,
    content_type: "course",
    value: isNaN(localAmount) ? amount : localAmount,
    order_id: order_id,
  };
};

const zoomAttendanceEventsGTM = (
  event,
  userID = undefined,
  emailID = undefined,
  phoneNumber = undefined,
  first_name = undefined,
  last_name = undefined,
  city = undefined,
  region = undefined,
  country = undefined,
  postcode = undefined
) => {
  return {
    event,
    ...(userID && { userID }),
    ...(emailID && { emailID }),
    ...(phoneNumber && { phoneNumber }),
    ...(first_name && { first_name }),
    ...(last_name && { last_name }),
    ...(city && { city }),
    ...(region && { region }),
    ...(country && { country }),
    ...(postcode && { postcode }),
  }
}
export {
  checkoutActivityGtm,
  zoomAttendanceEventsGTM
};
