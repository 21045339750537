import axios from "axios";
const FBActivityDispatcher = (payload, token, id) => {
  // ! Sending activity to LS
  axios
    .post(
      process.env.NEXT_PUBLIC_FB_CONVERSIONS_API_PAYLOAD_DISPATCH,
      {
        payload: payload,
      },
      {
        headers: {
          Authorization: token,
          "X-SSUID": id,
        },
      }
    )
    .then(() => {})
    .catch((err) => console.log("fb conv err", err));
};
export { FBActivityDispatcher };
