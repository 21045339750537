import React, { useState, useEffect, useRef } from "react";
import PrimaryButton from "../components/buttons/PrimaryButton";
import Footer from "./Footer";
import Link from "next/link";
import uuid from "react-uuid";
import { positions, useAlert } from "react-alert";
import moengageEvent from "../helpers/MoengageEventTracking";
import {
  pageVisitAttributes,
  clickToHomepageAttributes,
  mainMenuClickAttributes,
  bookTrialClickAttributes,
  visitLiveAttributes,
} from "../helpers/MoengageAttributeCreators";
import {
  cartDrawerOpen,
  logoutUser,
  changeNumber,
  setCurrency,
  setUtmObject,
  setReferrerDetails,
  setContext,
  setAdName,
  setBannerUpdateDate,
  setBannerActive,
} from "../store/actions/rootActions";
import { getCourses } from "../store/actions/authActions";
// ! GSAP imports
import { gsap } from "gsap/dist/gsap";
import { ScrollToPlugin } from "gsap/dist/ScrollToPlugin";
import { useSelector, useDispatch } from "react-redux";
import CartDrawer from "../components/drawers/CartDrawer";
import AuthSignUp from "../components/modals/AuthSignUp";
import AuthLogin from "../components/modals/AuthLogin";
import FreeLogin from "../components/modals/FreeLogin";
import axios from "axios";
// import coursesDataNew from "../store/staticData/coursesDataRevisedv14.json";
import { leadsquaredActivityDispatcher } from "../helpers/leadsquaredActivityDispatcher";
import {
  bookTrialClickActivity,
  cartClickActivity,
  mainMenuClickActivity,
} from "../helpers/leadsquaredAttributeCreators";
import { useRouter } from "next/dist/client/router";
import { utm } from "url-utm-params";
// import TopBar from "../components/TopBar";
import queryString from "query-string";
import { getTimezone } from "../helpers/getTimezone";
import { FBActivityDispatcher } from "../helpers/fbConversionsHelpers/FBActivityDispatcher";
import { pageViewActivityFBPayload } from "../helpers/fbConversionsHelpers/createFBActivityPayload";
import GsvStrip from "../components/Strips/GsvStrip";
import SummerCampStrip from "../components/Strips/SummerCampStrip";
import { setValidCountryCodes } from "../store/actions/authActions";
import { setAuthDestinationPage } from "../store/actions/rootActions";
import useGlobalHooks from "../hooks/globalHook";

// ! Registering plugin
gsap.registerPlugin(ScrollToPlugin);
function NavFooterLayout({
  children,
  noBg,
  coursesDataNew,
  showBuyNow,
  hideNavBg,
  yellowBg,
  showFooter = true,
  showModal,
  isInvalidUrl = false,
}) {
  // banner upadate date
  const CURRENT_BANNER_UPDATE_VERSION = useSelector(
    (state) => state.utility.bannerUpdateDate
  );
  const isNextEduUser = useSelector(
    (state) => state.dashboard_session.isNextEduUser
  );
  // ! contants
  const SHOW_SUPPORT_ICONS =
    window?.location?.pathname?.includes("/live") ||
    window?.location?.pathname?.includes("/teacher-dashboard") ||
    window?.location?.pathname?.includes("/preparation");

  const SHOW_JOIN_CLASS = window?.location?.pathname == "/" || isInvalidUrl;

  // ! Redux states
  const cart = useSelector((state) => state.checkout.cart);
  const cartDrawer = useSelector((state) => state.checkout.cartDrawer);
  const authToken = useSelector((state) => state.auth.authToken);
  const userDetails = useSelector((state) => state.auth.userDetails);
  const referrerDetails = useSelector((state) => state.utility.referrerDetails);
  const authOtpRequested = useSelector((state) => state.auth.authOtpRequested);
  const utmObject = useSelector((state) => state.utility.utmObject);
  const directBuyDetails = useSelector(
    (state) => state.checkout.directBuyDetails
  );
  const ad_name = useSelector((state) => state.utility.ad_name);
  const bannerActive = useSelector((state) => state.utility.bannerActive);
  const validCountryCodes = useSelector(
    (state) => state.auth.validCountrycodes
  );
  const teacherProfiles = useSelector((state) => state.auth.teacherProfiles);

  const [initialRender, setInitialRender] = useState(true);
  const dispatch = useDispatch();
  const router = useRouter();
  const alert = useAlert();
  const [_, useLocationDetails] = useGlobalHooks();
  // ! State for responsive mode
  const [responsiveMode, setResponsiveMode] = useState(false);
  const [menuDrawerClass, setMenuDrawerClass] = useState(
    cartDrawer
      ? `spark-layout-navbar bring-above hidden ${
          hideNavBg ? "remove-bg " : ""
        }`
      : `spark-layout-navbar hidden ${hideNavBg ? "remove-bg " : ""}`
  );
  const [desktopNavbarClass, setDesktopNavbarClass] = useState(
    `spark-layout-navbar navbar-scrolling ${hideNavBg ? "remove-bg " : ""}`
  );
  // notification banner force update logic
  // to force banner update, change the BANNER_UPDATE_VERSION to current Javascript date value
  useEffect(() => {
    const BANNER_UPDATE_VERSION = 1671787097860;

    if (BANNER_UPDATE_VERSION !== CURRENT_BANNER_UPDATE_VERSION) {
      dispatch(setBannerUpdateDate(BANNER_UPDATE_VERSION));
      dispatch(setBannerActive(true));
    }
  }, []);
  useEffect(() => {
    if (cartDrawer) {
      setMenuDrawerClass("spark-layout-navbar bring-above");
      setDesktopNavbarClass("spark-layout-navbar bring-above navbar-scrolling");
    } else {
      setMenuDrawerClass("spark-layout-navbar hidden");
      setDesktopNavbarClass(
        `spark-layout-navbar navbar-scrolling ${hideNavBg ? "remove-bg " : ""}`
      );
    }
  }, [cartDrawer]);
  const allCourses = useSelector((state) => state.auth.allCourses);

  useEffect(() => {
    if (window.innerWidth < 860 && !initialRender) {
      setResponsiveMode(true);
    }
    window.addEventListener("resize", () => {
      if (window.innerWidth < 860 && !initialRender) {
        setResponsiveMode(true);
      } else {
        setResponsiveMode(false);
      }
    });
  }, [initialRender]);

  useEffect(() => {
    useLocationDetails();
  }, []);

  // ! this is temporary fix only
  useEffect(() => {
    const isTeacherDashboard =
      window.location.href.includes("/teacher-dashboard") ||
      window.location.href.includes("preparation");
    const isLivePage = window.location.href.includes("live");

    if (authToken.length === 0 && isTeacherDashboard) {
      router.push("/login");
      dispatch(setAuthDestinationPage("/teacher-dashboard"));
    } else {
      if (teacherProfiles?.length === 0 && isTeacherDashboard) {
        alertInfo("Please Login with Teacher Account.");
        router.push("/live");
      }
      // ! *** olny for now
      // if (teacherProfiles?.length > 0 && isTeacherDashboard) {
      //   // ! uncomming for now
      //   alertInfo(
      //     "Apologies for the inconvenience. Please use the live page instead of the teacher dashboard."
      //   );
      //   router.push("/live");
      // }
    }
  }, [authToken]);

  useEffect(() => {
    if (!window.localStorage.visitor_uuid) {
      window.localStorage.setItem("visitor_uuid", uuid());
    }
    // ! Setting the courses data and currency on initial load

    // if (allCourses?.length === 0) {
    dispatch(getCourses(coursesDataNew));
    // }
    if (authOtpRequested && authToken?.length === 0) dispatch(changeNumber());
    // ! Moengage event firing (add kingdom and genus)
    // moengageEvent("Page_View", pageVisitAttributes("", ""));
    // ! /invite event will be handled in invite component with some additional params
    if (!window.location.href.includes("book-a-trial/onboarding/invite/")) {
      // moengageEvent("Page_View", pageVisitAttributes("", ""));
    }
    // ! Setting the UTM object
    let utmObject = utm(window.location.href);
    if (Object.keys(utmObject).length > 0) {
      dispatch(setUtmObject(utm(window.location.href)));
    }
    setInitialRender(false);
    // if (authToken.length > 0) {
    //   FBActivityDispatcher(
    //     pageViewActivityFBPayload(
    //       userDetails.id,
    //       window?.location?.pathname || ""
    //     ),
    //     authToken,
    //     userDetails.id
    //   );
    // }
  }, []);

  // !useEffect to fetch country codes.
  useEffect(() => {
    if (validCountryCodes.length > 0) return;
    axios
      .get(process.env.NEXT_PUBLIC_FETCH_COUNTRY_CODES)
      .then((res) => {
        console.log(res, "res");
        dispatch(setValidCountryCodes(res.data.codes));
      })
      .catch((err) => console.log(err, "error nn"));
  }, []);

  useEffect(() => {
    dispatch(setCurrency());
  }, []);
  // ! Use effect to identify HJ
  // useEffect(() => {
  //   if (authToken && window?.hj) {
  //     // let distinct_id = window.mixpanel?.cookie?.props?.distinct_id;
  //     window.hj("identify", userDetails.id, {
  //       timezone: getTimezone(),
  //     });
  //   }
  // }, []);
  // ! Effect to store referral params
  useEffect(() => {
    let queryDetails = queryString.parse(window.location.search);
    // console.log("query", queryDetails);
    if (queryDetails.mwr) {
      dispatch(setReferrerDetails({ referrer_code: queryDetails.mwr }));
    }
    if (queryDetails.fbclid) {
      window.localStorage.fbc = queryDetails.fbclid;
    }
    if (queryDetails.gclid) {
      window.localStorage.gc = queryDetails.gclid;
    }
    if (queryDetails["ad name"]) {
      dispatch(setAdName(queryDetails["ad name"]));
    }
  }, []);
  useEffect(() => {
    if (utmObject?.utm_source && authToken?.length > 0 && userDetails?.id) {
      axios.patch(
        `${process.env.NEXT_PUBLIC_UPDATE_USER_API}${userDetails.id}`,
        {
          user: {
            facebook_lead: {
              user_agent: window?.navigator?.userAgent || "",
              fb_click_id: window?.localStorage?.fbc || "",
              google_click_id: window?.localStorage?.gc || "",
              utm_campaign: utmObject?.utm_campaign || "",
              utm_content: utmObject?.utm_content || "",
              utm_medium: utmObject?.utm_medium || "",
              utm_source: utmObject?.utm_source || "",
              ad_name: ad_name || "",
              source: window.location.pathname,
            },
          },
        },
        { headers: { Authorization: authToken, "X-SSUID": userDetails.id } }
      );
    }
  }, [utmObject, window?.localStorage?.fbc, userDetails, authToken]);
  const containerLayout = useRef(null);

  const [cartDrawerClass, setCartDrawerClass] = useState(
    cartDrawer
      ? "spark-layout-navbar--cart-drawer visible"
      : "spark-layout-navbar--cart-drawer hidden"
  );
  useEffect(() => {
    cartDrawer
      ? setCartDrawerClass("spark-layout-navbar--cart-drawer visible")
      : setCartDrawerClass("spark-layout-navbar--cart-drawer hidden");
  }, [cartDrawer]);
  const signupModalOpen = useSelector((state) => state.auth.signupModalOpen);
  const loginModalOpen = useSelector((state) => state.auth.loginModalOpen);
  const freeLoginModalOpen = useSelector(
    (state) => state.auth.freeLoginModalOpen
  );
  // ! To show book a trial or not
  const showBookATrial = () => {
    let route = window.location.pathname;
    let search = window.location.search;
    if (isNextEduUser) return false;
    if (
      route.includes("book-a-trial") ||
      route.includes("workshop") ||
      route.includes("checkout") ||
      route.includes("payment-successful") ||
      route.includes("booked") ||
      route.includes("live") ||
      route.includes("teacher-dashboard") ||
      route.includes("preparation") ||
      route.includes("oktoberfest") ||
      route.includes("learn/metronome") ||
      search?.includes("b=t")
      // || (search.includes("w=t") ? false : route.includes("winter-camp"))
    )
      return false;
    if (
      directBuyDetails?.isDirectBuy &&
      route.includes(directBuyDetails?.urlCourseName)
    )
      return false;
    return true;
  };

  const alertInfo = (message) => {
    alert.show(message, {
      type: "success",
      position: positions.TOP_CENTER,
    });
  };

  // ! Scroll-to function
  const scroller = () => {
    gsap.to(window, {
      scrollTo: document.getElementById("courses-page-header").offsetTop - 70,
      ease: "ease-out",
      duration: 1,
      scrollBehavior: "smooth",
    });
  };
  useEffect(() => {
    if (menuDrawerClass === "spark-layout-navbar bring-above visible") {
      window.addEventListener("click", (ev) => {
        ev.preventDefault();
        ev.stopImmediatePropagation();
        let drawer = document.querySelector(`.${menuDrawerClass}`);
        let toggle = document.querySelector(".menu-toggle");
        if (ev.target !== drawer && ev.target !== toggle)
          setMenuDrawerClass("spark-layout-navbar hidden");
      });
    } else {
      window.removeEventListener("click");
    }
  }, [menuDrawerClass]);
  const [showAlertBar, setShowAlertBar] = useState(false);
  const canShowAlertBar = (route) => {
    return false; //! to remove alert bar
    // if (route.includes("games")) return false;
    // if (route.includes("book-a-trial")) return false;
    // if (route.includes("checkout")) return false;
    // if (route.includes("winter-camp")) return false;
    // if (route.includes("courses")) return false;
    // if (route.includes("explore")) return false;
    // if (route.includes("play")) return false;

    // return true;
  };
  useEffect(() => {
    if (window) {
      setShowAlertBar(canShowAlertBar(window.location.pathname));
    }
  }, []);
  const isTedEd = () => {
    if (window.location.pathname.includes("ted-ed-club"))
      return "https://book.sparkstudio.co/?ad_id=A13TedEd";
    return "/book-a-trial";
  };
  //! Useeffect to set active/hidden state for gsv banner based on url
  const [active, setActive] = useState(false);
  useEffect(() => {
    let route = window.location.pathname;
    if (
      route.includes("/courses/") ||
      route.includes("about-us") ||
      route.includes("faq")
    ) {
      setActive(true);
    } else if (route == "/") {
      setActive(true);
    } else {
      setActive(false);
    }
  }, [window.location.pathname]);

  // Check if nextEdu user

  // const checkIfUserInNextFrame = () => {
  //   let keywords = [];
  //   let splitPathNames = window.location.href.split("/");
  //   let splitHost = window.location.host.split(".");
  //   keywords = [...splitPathNames, ...splitHost];
  //   console.log(splitPathNames, splitHost, keywords, "keyWords for nextEdu");
  //   console.log(document.referrer, "document referrer");
  //   if (
  //     keywords.includes("nova") ||
  //     keywords.includes("nexterp") ||
  //     keywords.includes("nlp") ||
  //     keywords.includes("nextstore") ||
  //     keywords.includes("nova.nexterp.in")
  //   )
  //     return true;
  //   return false;
  // };

  // useEffect(() => {
  //   if (checkIfUserInNextFrame()) dispatch(setNextEduUser(true));
  //   else dispatch(setNextEduUser(false));
  // }, []);

  return (
    <div
      className={
        window.isSparkNative
          ? "nav-footer-layout is-app-booking"
          : showAlertBar
          ? window?.location?.pathname?.includes("/book-a-trial") ||
            window?.location?.pathname?.includes("/games") ||
            noBg
            ? "nav-footer-layout show-alert no-bg"
            : `nav-footer-layout show-alert ${yellowBg ? "yellow-bg" : ""}`
          : window?.location?.pathname?.includes("/book-a-trial") ||
            window?.location?.pathname?.includes("/games") ||
            noBg
          ? "nav-footer-layout no-bg"
          : `nav-footer-layout ${yellowBg ? "yellow-bg" : ""}`
      }
      id="layout"
      ref={containerLayout}
    >
      {responsiveMode ? (
        <>
          {/* {bannerActive && active ? <SummerCampStrip /> : null} */}
          <nav
            className={menuDrawerClass}
            // style={
            //   window.isSparkNative
            //     ? { display: "none" }
            //     : bannerActive && active
            //     ? { top: "53px" }
            //     : { top: "0" }
            // }
            style={window.isSparkNative ? { display: "none" } : { top: "0" }}
          >
            {/* {showAlertBar && (
              <div
                className="spark-layout-navbar__alert-bar"
                onClick={() => {
                  router.push("/courses/winter-camp");
                }}
              >
                <span>A Winter Wonderfest for your child?</span>
                <span>
                  <a>Join Now!</a>
                </span>
              </div>
            )} */}
            <div
              className={`spark-layout-navbar__left ${
                window?.isSparkNativeLive ? "app" : null
              }`}
            >
              <Link
                href="/"
                onClick={() =>
                  moengageEvent(
                    "Click_To_Home_Page",
                    clickToHomepageAttributes()
                  )
                }
              >
                <a>
                  <img
                    src={`${process.env.NEXT_PUBLIC_ALL_ASSETS_API}sparkLogo.png`}
                    alt="Spark Studio"
                  />
                </a>
              </Link>
            </div>
            <div className="trial-hamburger">
              {showBookATrial() && !showBuyNow ? (
                <PrimaryButton
                  buttonText="Book a FREE trial"
                  version="version-1"
                  linkTo={isTedEd()}
                  shine={true}
                  clickHandle={() => {
                    dispatch(setContext("trial"));
                    moengageEvent(
                      "Main_Menu_Click",
                      mainMenuClickAttributes(
                        1,
                        "Book a FREE trial",
                        isTedEd(),
                        3,
                        4,
                        3
                      )
                    );
                    if (authToken.length > 0) {
                      leadsquaredActivityDispatcher(
                        mainMenuClickActivity(
                          1,
                          "Book a FREE trial",
                          isTedEd(),
                          3,
                          3
                        ),
                        authToken,
                        userDetails.id
                      );
                    }
                    moengageEvent(
                      "Book_Trial_Click",
                      bookTrialClickAttributes(
                        "Book a FREE trial",
                        isTedEd(),
                        1,
                        "Navbar",
                        ""
                      )
                    );
                    if (authToken.length > 0) {
                      leadsquaredActivityDispatcher(
                        bookTrialClickActivity(
                          "Book a FREE trial",
                          isTedEd(),
                          1,
                          "Navbar",
                          window.location.pathname
                        ),
                        authToken,
                        userDetails.id
                      );
                    }
                  }}
                />
              ) : null}
              {showBuyNow && (
                <PrimaryButton
                  buttonText="Buy Now"
                  shine={true}
                  version="version-1"
                  clickHandle={scroller}
                />
              )}
              {!SHOW_SUPPORT_ICONS && (
                <img
                  src={`${process.env.NEXT_PUBLIC_ALL_ASSETS_API}hamburger.svg`}
                  alt=""
                  className="menu-toggle"
                  onClick={() => {
                    menuDrawerClass === "spark-layout-navbar hidden"
                      ? setMenuDrawerClass(
                          "spark-layout-navbar bring-above visible"
                        )
                      : setMenuDrawerClass("spark-layout-navbar hidden");
                  }}
                />
              )}
              {SHOW_SUPPORT_ICONS && (
                <li
                  onClick={() => {
                    console.log("pressed out");
                    moengageEvent(
                      "Main_Menu_Click",
                      mainMenuClickAttributes(3, "Support", "", 1, 0, 5)
                    );
                    showModal();
                    document.body.style.overflow = "hidden";
                  }}
                  style={{ display: "flex" }}
                >
                  <PrimaryButton buttonText={"Support"} version={"version-1"} />
                </li>
              )}
              {!window.isSparkNativeLive &&
              !isNextEduUser &&
              ((authToken?.length > 0 && SHOW_SUPPORT_ICONS) ||
                window?.location?.pathname?.includes("/homework-upload") ||
                window?.location?.pathname?.includes("/book-a-trial")) ? (
                <li
                  className="spark-layout-navbar__right--list-item roundedLogout_responsive"
                  onClick={() => {
                    if (isNextEduUser) return;
                    console.log("pressed out");
                    moengageEvent(
                      "Main_Menu_Click",
                      mainMenuClickAttributes(
                        3,
                        "about-us",
                        "/about-us",
                        1,
                        0,
                        5
                      )
                    );
                    dispatch(logoutUser());
                  }}
                >
                  <a className="spark-layout-navbar__right--list-item">
                    Logout
                  </a>
                </li>
              ) : null}
              {cart?.length > 0 ? (
                <div
                  className="spark-layout-navbar__right--list-item cart-icon"
                  onClick={() => {
                    dispatch(cartDrawerOpen());
                    moengageEvent(
                      "Main_Menu_Click",
                      mainMenuClickAttributes(0, "cart-icon", "", 3, 2, "")
                    );
                    if (authToken.length > 0) {
                      leadsquaredActivityDispatcher(
                        mainMenuClickActivity(0, "cart-icon", "", 3, 0),
                        authToken,
                        userDetails.id
                      );
                    }
                    if (authToken.length > 0) {
                      let cartQty = cart.reduce((a, item) => item.qty + a, 0);
                      leadsquaredActivityDispatcher(
                        cartClickActivity(
                          cart.length > 0 ? cartQty : 0,
                          window.location.pathname
                        ),
                        authToken,
                        userDetails.id
                      );
                    }
                  }}
                >
                  <img
                    src={`${process.env.NEXT_PUBLIC_ALL_ASSETS_API}cartIconBlack.svg`}
                    alt=""
                  />
                  {cart?.length > 0 ? (
                    <div className="cart-bubble">
                      {cart?.reduce((a, b) => a + b.qty, 0)}
                    </div>
                  ) : null}
                </div>
              ) : null}
            </div>

            <div className="spark-layout-navbar__right">
              <ul className="spark-layout-navbar__right--list">
                <div>
                  <li className="spark-layout-navbar__right--list-item">
                    <Link
                      href="/courses"
                      onClick={() => {
                        moengageEvent(
                          "Main_Menu_Click",
                          mainMenuClickAttributes(
                            2,
                            "all-courses",
                            "/courses",
                            1,
                            2,
                            2
                          )
                        );
                        if (authToken.length > 0) {
                          leadsquaredActivityDispatcher(
                            mainMenuClickActivity(
                              2,
                              "all-courses",
                              "/courses",
                              1,
                              2
                            ),
                            authToken,
                            userDetails.id
                          );
                        }
                      }}
                    >
                      <a>Explore Courses</a>
                    </Link>
                  </li>

                  {/* <li className="spark-layout-navbar__right--list-item">
                  <Link
                    href="/workshops"
                    onClick={() => {
                      moengageEvent(
                        "Main_Menu_Click",
                        mainMenuClickAttributes(
                          2,
                          "workshops",
                          "/workshops",
                          1,
                          2,
                          2
                        )
                      );
                      if (authToken.length > 0) {
                        leadsquaredActivityDispatcher(
                          mainMenuClickActivity(
                            2,
                            "workshops",
                            "/workshops",
                            1,
                            2
                          ),
                          authToken,
                          userDetails.id
                        );
                      }
                    }}
                  >
                    <a>Workshops</a>
                  </Link>
                </li> */}
                  <li className="spark-layout-navbar__right--list-item">
                    <Link
                      href="/about-us"
                      onClick={() =>
                        moengageEvent(
                          "Main_Menu_Click",
                          mainMenuClickAttributes(
                            3,
                            "about-us",
                            "/about-us",
                            1,
                            0,
                            5
                          )
                        )
                      }
                    >
                      <a>About Us</a>
                    </Link>
                  </li>

                  {/* <li className="spark-layout-navbar__right--list-item">
                  <Link
                    href="/faq"
                    onClick={() =>
                      moengageEvent(
                        "Main_Menu_Click",
                        mainMenuClickAttributes(3, "about-us", "/faq", 1, 0, 5)
                      )
                    }
                  >
                    <a>FAQs</a>
                  </Link>
                </li> */}

                  {SHOW_JOIN_CLASS ? (
                    <li
                      className="spark-layout-navbar__right--list-item join-class-text"
                      onClick={() => {
                        if (authToken.length > 0)
                          router.push("/dashboard/all-classes");
                        else {
                          dispatch(
                            setAuthDestinationPage("/dashboard/all-classes")
                          );
                          router.push("/login");
                        }
                      }}
                    >
                      <div>
                        <a className="spark-layout-navbar__right--list-item">
                          Join Class
                        </a>
                      </div>
                    </li>
                  ) : null}

                  {!isNextEduUser &&
                  ((authToken?.length > 0 && SHOW_SUPPORT_ICONS) ||
                    window?.location?.pathname?.includes("/homework-upload") ||
                    (window?.location?.pathname?.includes("/book-a-trial") &&
                      process.env.NEXT_PUBLIC_ENV !== "production")) ? (
                    <li
                      className="spark-layout-navbar__right--list-item"
                      onClick={() => {
                        if (isNextEduUser) return;
                        dispatch(logoutUser());
                        // window.__posthog.reset();
                      }}
                    >
                      Logout
                    </li>
                  ) : null}
                </div>
              </ul>
            </div>
            <div className={cartDrawerClass}>
              <CartDrawer selectedCourses={cart} />
            </div>
          </nav>
        </>
      ) : (
        <>
          {/* {bannerActive && active ? <SummerCampStrip /> : null} */}
          <nav
            className={desktopNavbarClass}
            // style={bannerActive && active ? { top: "64px" } : { top: "0" }}
            style={{ top: "0" }}
          >
            {/* {showAlertBar && (
              <div
                className="spark-layout-navbar__alert-bar"
                onClick={() => {
                  router.push("/courses/winter-camp");
                }}
              >
                <span>A Winter Wonderfest for your child?</span>
                <span>
                  <a>Join Now!</a>
                </span>
              </div>
            )} */}

            <div
              className={`spark-layout-navbar__left ${
                window?.isSparkNativeLive ? "app" : null
              }`}
            >
              <Link
                href="/"
                onClick={() =>
                  moengageEvent(
                    "Click_To_Home_Page",
                    clickToHomepageAttributes()
                  )
                }
              >
                <a>
                  <img
                    src={`${process.env.NEXT_PUBLIC_ALL_ASSETS_API}sparkLogo.png`}
                    alt="Spark Studio"
                  />
                </a>
              </Link>
            </div>
            <div className="spark-layout-navbar__right">
              <ul className="spark-layout-navbar__right--list">
                {!SHOW_SUPPORT_ICONS && (
                  <li className="spark-layout-navbar__right--list-item">
                    <Link
                      href="/courses"
                      onClick={() => {
                        moengageEvent(
                          "Main_Menu_Click",
                          mainMenuClickAttributes(
                            2,
                            "all-courses",
                            "/courses",
                            1,
                            2,
                            2
                          )
                        );
                        if (authToken.length > 0) {
                          leadsquaredActivityDispatcher(
                            mainMenuClickActivity(
                              2,
                              "all-courses",
                              "/courses",
                              1,
                              2
                            ),
                            authToken,
                            userDetails.id
                          );
                        }
                      }}
                    >
                      <a>Explore Courses</a>
                    </Link>
                  </li>
                )}
                {!SHOW_SUPPORT_ICONS && (
                  <li className="spark-layout-navbar__right--list-item">
                    <Link
                      href="/about-us"
                      onClick={() =>
                        moengageEvent(
                          "Main_Menu_Click",
                          mainMenuClickAttributes(
                            3,
                            "about-us",
                            "/about-us",
                            1,
                            0,
                            5
                          )
                        )
                      }
                    >
                      <a>About Us</a>
                    </Link>
                  </li>
                )}
                {SHOW_SUPPORT_ICONS && (
                  <li
                    onClick={() => {
                      console.log("pressed out");
                      moengageEvent(
                        "Main_Menu_Click",
                        mainMenuClickAttributes(3, "Support", "", 1, 0, 5)
                      );
                      showModal();
                      document.body.style.overflow = "hidden";
                    }}
                  >
                    <PrimaryButton
                      buttonText={"Support"}
                      version={"version-1"}
                    />
                  </li>
                )}
                {!window.isSparkNative &&
                !isNextEduUser &&
                ((authToken?.length > 0 && SHOW_SUPPORT_ICONS) ||
                  window?.location?.pathname?.includes("/homework-upload") ||
                  window?.location?.pathname?.includes("/book-a-trial")) ? (
                  <li
                    className="spark-layout-navbar__right--list-item roundedLogout"
                    onClick={() => {
                      console.log("pressed out");
                      if (isNextEduUser) return;
                      moengageEvent(
                        "Main_Menu_Click",
                        mainMenuClickAttributes(
                          3,
                          "about-us",
                          "/about-us",
                          1,
                          0,
                          5
                        )
                      );
                      dispatch(logoutUser());
                    }}
                  >
                    {/* <Link
                      href=""
                      onClick={() =>
                        moengageEvent(
                          "Main_Menu_Click",
                          mainMenuClickAttributes(
                            3,
                            "about-us",
                            "/about-us",
                            1,
                            0,
                            5
                          )
                        )
                      }
                    > */}
                    <a
                      className="spark-layout-navbar__right--list-item "
                      // onClick={() => {
                      //   dispatch(logoutUser());
                      //   console.log("pressed");
                      //   // window.__posthog.reset();
                      // }}
                    >
                      Logout
                    </a>
                    {/* </Link> */}
                  </li>
                ) : null}
                <li className="spark-layout-navbar__right--list-item">
                  {showBookATrial() && !showBuyNow ? (
                    <PrimaryButton
                      buttonText="Book a FREE trial"
                      version="version-1"
                      linkTo={isTedEd()}
                      shine={true}
                      clickHandle={() => {
                        dispatch(setContext("trial"));
                        moengageEvent(
                          "Main_Menu_Click",
                          mainMenuClickAttributes(
                            1,
                            "Book a FREE trial",
                            isTedEd(),
                            3,
                            4,
                            3
                          )
                        );
                        if (authToken.length > 0) {
                          leadsquaredActivityDispatcher(
                            mainMenuClickActivity(
                              1,
                              "Book a FREE trial",
                              isTedEd(),
                              3,
                              3
                            ),
                            authToken,
                            userDetails.id
                          );
                        }
                        moengageEvent(
                          "Book_Trial_Click",
                          bookTrialClickAttributes(
                            "Book a FREE trial",
                            isTedEd(),
                            1,
                            "Navbar",
                            ""
                          )
                        );
                        if (authToken.length > 0) {
                          leadsquaredActivityDispatcher(
                            bookTrialClickActivity(
                              "Book a FREE trial",
                              isTedEd(),
                              1,
                              "Navbar",
                              window.location.pathname
                            ),
                            authToken,
                            userDetails.id
                          );
                        }
                      }}
                    />
                  ) : null}
                  {showBuyNow && (
                    <PrimaryButton
                      buttonText="Buy Now"
                      shine={true}
                      version="version-1"
                      clickHandle={scroller}
                    />
                  )}
                </li>{" "}
                {SHOW_JOIN_CLASS ? (
                  <li
                    className="spark-layout-navbar__right--list-item join-class"
                    onClick={() => {
                      if (authToken.length > 0)
                        router.push("/dashboard/all-classes");
                      else {
                        dispatch(
                          setAuthDestinationPage("/dashboard/all-classes")
                        );
                        router.push("/login");
                      }
                    }}
                  >
                    <Link href="/dashboard/all-classes">
                      <a className="spark-layout-navbar__right--list-item">
                        Join Class
                      </a>
                    </Link>
                  </li>
                ) : null}
                {cart?.length > 0 ? (
                  <li
                    className="spark-layout-navbar__right--list-item cart-icon"
                    onClick={() => {
                      dispatch(cartDrawerOpen());
                      moengageEvent(
                        "Main_Menu_Click",
                        mainMenuClickAttributes(0, "cart-icon", "", 3, 2, "")
                      );
                      if (authToken.length > 0) {
                        leadsquaredActivityDispatcher(
                          mainMenuClickActivity(0, "cart-icon", "", 3, 0),
                          authToken,
                          userDetails.id
                        );
                      }
                      if (authToken.length > 0) {
                        let cartQty = cart.reduce((a, item) => item.qty + a, 0);
                        leadsquaredActivityDispatcher(
                          cartClickActivity(
                            cart.length > 0 ? cartQty : 0,
                            window.location.pathname
                          ),
                          authToken,
                          userDetails.id
                        );
                      }
                    }}
                  >
                    <img
                      src={`${process.env.NEXT_PUBLIC_ALL_ASSETS_API}cartIconBlack.svg`}
                      alt=""
                    />
                    {cart?.length > 0 ? (
                      <div className="cart-bubble">
                        {cart?.reduce((a, b) => a + b.qty, 0)}
                      </div>
                    ) : null}
                  </li>
                ) : null}
              </ul>
            </div>
            <div className={cartDrawerClass}>
              <CartDrawer selectedCourses={cart} />
            </div>
          </nav>
        </>
      )}
      {children}
      {signupModalOpen ? <AuthSignUp /> : null}
      {loginModalOpen ? <AuthLogin /> : null}
      {freeLoginModalOpen ? <FreeLogin /> : null}

      {!window.isSparkNative && showFooter && <Footer />}
    </div>
  );
}

export default NavFooterLayout;
