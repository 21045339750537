import React, { useState } from "react";
import PrimaryButton from "../buttons/PrimaryButton";
import CartCard from "../cards/CartCard";
import { useDispatch, useSelector } from "react-redux";
import {
  cartDrawerOpen,
  setPromoCode,
  removeQtyFromCart,
  setBuynowCourseId,
} from "../../store/actions/rootActions";
import workshopData from "../../store/staticData/paidWorkshopCourseData.json";
import { useRouter } from "next/router";
import moengageEvent from "../../helpers/MoengageEventTracking";
import { checkoutAttributes } from "../../helpers/MoengageAttributeCreators";
import allCourses from "../../store/staticData/coursesDataRevisedv14.json";
import comboData from "../../store/staticData/comboCourseData.json";
import { courseDetailsParser } from "../../helpers/courseDetailsParser";
import { leadsquaredActivityDispatcher } from "../../helpers/leadsquaredActivityDispatcher";
import { checkoutActivity } from "../../helpers/leadsquaredAttributeCreators";
import { getPriceOfCourse } from "../../helpers/getPriceOfCourse";
import { gtmActivityDispatcher } from "../../helpers/gtmEventsHelpers/gtmActivityDispatcher";
import { checkoutActivityGtm } from "../../helpers/gtmEventsHelpers/gtmActivityCreator";
import { openInNewTab } from "../../helpers/openInNewTab";
function CartDrawer({ selectedCourses }) {
  const router = useRouter();
  const dispatch = useDispatch();
  const coursesData = allCourses.map((course) => courseDetailsParser(course));
  const authToken = useSelector((state) => state.auth.authToken);
  const userDetails = useSelector((state) => state.auth.userDetails);
  const currency = useSelector((state) => state.utility.currency);
  const isNextEduUser = useSelector(
    (state) => state.dashboard_session.isNextEduUser
  );
  const directBuyDetails = useSelector(
    (state) => state.checkout.directBuyDetails
  );
  // ! get combo
  const getCurrentCombo = (comboId) => {
    let combos = comboData.map((combo) => courseDetailsParser(combo));
    return combos.find((item) => item.courseId === comboId);
  };
  // ! get paid workshop
  const getCurrentPaidWorkshop = (workshopId) => {
    let workshops = workshopData.map((ws) => courseDetailsParser(ws));
    return workshops.find((item) => item.courseId === workshopId);
  };
  const goToCheckout = () => {
    // !setting empty to avoid some content in checkout modal. (Ref: checkoutLoginModal.js )
    dispatch(setBuynowCourseId(""));
    let totalQty = selectedCourses.reduce((acc, course) => acc + course.qty, 0);
    let cartTotal = selectedCourses.reduce((acc, course) => {
      let found = coursesData.find((item) => item.courseId === course.courseId);
      if (!found) {
        let combo = getCurrentCombo(course.courseId);
        if (combo) {
          return acc + getPriceOfCourse(combo, currency) * course.qty;
        } else {
          return (
            acc +
            getPriceOfCourse(
              getCurrentPaidWorkshop(course.courseId),
              currency
            ) *
              course.qty
          );
        }
      }
      return acc + getPriceOfCourse(found, currency) * course.qty;
    }, 0);
    moengageEvent(
      "Checkout",
      checkoutAttributes(
        totalQty,
        "null",
        window.location.pathname,
        cartTotal,
        8,
        "",
        0
      )
    );
    if (authToken.length > 0) {
      leadsquaredActivityDispatcher(
        checkoutActivity(
          totalQty,
          "no image",
          window.location.pathname,
          cartTotal
        ),
        authToken,
        userDetails.id
      );
    }
    let allCourseIds = "",
      allCourseNames = "",
      allCourseVerticals = "";
    selectedCourses.forEach((course) => {
      allCourseIds += `${course.courseId},`;
      let found = coursesData.find((item) => item.courseId === course.courseId);
      if (found) {
        allCourseNames += `${found.courseName},`;
        allCourseVerticals += `${found.vertical},`;
        return;
      }
      return;
    });
    // ! Fire page view evnt on GTM
    gtmActivityDispatcher(
      checkoutActivityGtm(
        "Fb- Initiate Checkout",
        currency,
        allCourseIds,
        allCourseVerticals,
        allCourseNames,
        cartTotal
      )
    );
    dispatch(cartDrawerOpen());
    isNextEduUser ? router.push("/checkout") : openInNewTab("/checkout");
  };
  return (
    <div className="cart-drawer__wrapper">
      <div className="cart-drawer">
        <img
          src={`${process.env.NEXT_PUBLIC_ALL_ASSETS_API}smallCross.svg`}
          alt=""
          className="cart-drawer__close-button"
          onClick={() => dispatch(cartDrawerOpen())}
        />
        <h1 className="cart-drawer__header">
          {selectedCourses.length > 0 ? "Courses in cart" : "Cart is empty!"}
        </h1>
        {selectedCourses.length > 0 && coursesData.length > 0
          ? selectedCourses.map((course, index) => {
              let found = coursesData.find(
                (item) => item.courseId === course.courseId
              );
              if (course.qty > 0 && found) {
                return (
                  <CartCard
                    key={index}
                    courseName={found.displayName}
                    courseCategory={found.vertical}
                    courseAgeGroup={`${found.minAge}-${found.maxAge}`}
                    courseCurrency={currency}
                    coursePrice={getPriceOfCourse(found, currency)}
                    courseQty={course.qty}
                    courseColor={found.verticalThemeColorDark}
                    courseId={course.courseId}
                    version="--small"
                    courseDuration={found.numberOfClasses}
                  />
                );
              } else if (course.qty > 0 && !found) {
                let combos = comboData.map((combo) =>
                  courseDetailsParser(combo)
                );
                let foundCombo = combos.find(
                  (item) => item.courseId === course.courseId
                );
                if (foundCombo) {
                  return (
                    <CartCard
                      key={index}
                      courseName={foundCombo.displayName}
                      courseCategory={foundCombo.vertical}
                      courseAgeGroup={`${foundCombo.minAge}-${foundCombo.maxAge}`}
                      courseCurrency={currency}
                      coursePrice={getPriceOfCourse(foundCombo, currency)}
                      courseQty={course.qty}
                      courseColor={foundCombo.verticalThemeColorDark}
                      courseId={course.courseId}
                      version="--small"
                      courseDuration={foundCombo.numberOfClasses}
                    />
                  );
                } else {
                  let foundWorkshop = getCurrentPaidWorkshop(course.courseId);
                  if (foundWorkshop) {
                    return (
                      <CartCard
                        key={index}
                        courseName={foundWorkshop.displayName}
                        courseCategory={foundWorkshop.vertical}
                        courseAgeGroup={`${foundWorkshop.minAge}-${foundWorkshop.maxAge}`}
                        courseCurrency={currency}
                        coursePrice={getPriceOfCourse(foundWorkshop, currency)}
                        courseQty={course.qty}
                        courseColor={foundWorkshop.verticalThemeColorDark}
                        courseId={course.courseId}
                        version="--small"
                        courseDuration={foundWorkshop.numberOfClasses}
                      />
                    );
                  } else {
                    {
                      /* console.log("reached here"); */
                    }
                    dispatch(removeQtyFromCart(course.courseId));
                  }
                }
              }
            })
          : null}
      </div>
      {directBuyDetails.directBuyCoupon ? (
        <span className="cart-drawer__discount-text">
          Discount applied during checkout!
        </span>
      ) : null}
      {selectedCourses.length > 0 ? (
        <div className="cart-drawer__bottom">
          <PrimaryButton
            buttonText="Checkout"
            version="version-1"
            clickHandle={goToCheckout}
          />
        </div>
      ) : null}
    </div>
  );
}

export default CartDrawer;
