import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addQtyToCart,
  removeQtyFromCart,
  setPromoCodeResponse,
  setPromoCodeUiStates,
} from "../../store/actions/rootActions";
function CartCard({
  courseName,
  courseCategory,
  courseAgeGroup,
  courseCurrency,
  coursePrice,
  coursePriceOld,
  courseQty,
  courseColor,
  courseId,
  version,
  courseDuration,
  sessionDuration,
  batchDetails,
}) {
  const directBuyDetails = useSelector(
    (state) => state.checkout.directBuyDetails
  );
  const currency = useSelector((state) => state.utility.currency);
  const dispatch = useDispatch();
  const activeCourseOnCoursePage = useSelector(
    (state) => state.courses.activeCourseOnCoursePage
  );
  const parseCurrencySymbol = (currency, old = false) => {
    switch (currency) {
      case "INR":
        return (
          <>
            {Number(old ? coursePriceOld : coursePrice * courseQty)
              .toLocaleString("en-IN", {
                currency: currency,
                style: "currency",
              })
              .slice(0, -3)}
          </>
        );
      case "USD":
        return (
          <>
            {Number(old ? coursePriceOld : coursePrice * courseQty)
              .toLocaleString("en-US", {
                currency: currency,
                style: "currency",
              })
              .slice(0, -3)}
          </>
        );
      default:
        return `${old ? coursePriceOld : coursePrice * courseQty} ${currency}`;
    }
  };
  return (
    <div
      className={`cart-card__wrapper cart-card__wrapper${version}`}
      style={{
        background: `linear-gradient(114.28deg, ${courseColor}88 -12.61%, rgba(255, 255, 255, 0) 94.49%)`,
      }}
    >
      {version === "--small" ? (
        <div className="cart-card">
          <h1 className="cart-card__name">{courseName}</h1>
          <h2 className="cart-card__category">
            {courseDuration && <> Duration: {courseDuration} classes</>}
          </h2>
          <p className="cart-card__age-group">
            Age Group: {courseAgeGroup} years
          </p>
          <div className="cart-card__qty-price">
            <div className="quantity">
              <img
                src={`${process.env.NEXT_PUBLIC_ALL_ASSETS_API}binIcon.svg`}
                alt=""
                onClick={() => {
                  dispatch(removeQtyFromCart(courseId));
                  dispatch(setPromoCodeResponse({}));
                  dispatch(
                    setPromoCodeUiStates({
                      promoInputClassName: "cart-preview__promo-code--input",
                      promoTooltipClassName:
                        "cart-preview__promo-code--validation-tooltip",
                      promoTooltipText: "",
                    })
                  );
                }}
              />
              <span>{courseQty}</span>
              <img
                src={`${process.env.NEXT_PUBLIC_ALL_ASSETS_API}plusIcon.svg`}
                alt=""
                onClick={() => {
                  dispatch(addQtyToCart(courseId));
                  dispatch(setPromoCodeResponse({}));
                  dispatch(
                    setPromoCodeUiStates({
                      promoInputClassName: "cart-preview__promo-code--input",
                      promoTooltipClassName:
                        "cart-preview__promo-code--validation-tooltip",
                      promoTooltipText: "",
                    })
                  );
                }}
              />
            </div>
            <span className="price">{parseCurrencySymbol(courseCurrency)}</span>
          </div>
        </div>
      ) : null}
      {version === "--large" ? (
        <div className="cart-card cart-card--large">
          <div className="cart-card--large-left">
            <h1 className="cart-card__name" >
              {courseName}
            </h1>
            {courseDuration && <p className="cart-card__sessions">{courseDuration} sessions</p>}
            <p className="cart-card__age-group">
             {courseAgeGroup} years
            </p>
            {(courseId === "VI1111AJ" || courseId === "VI1215AS") &&
            batchDetails?.batchId ? (
              <p className="cart-card__batch" >
                <span>Starts on {batchDetails.parsedStartDate}</span>
                <span>{batchDetails.frequency}</span>
              </p>
            ) : (
              <p className="cart-card__batch">
                <span></span>
                <span></span>
              </p>
            )}

            {/* <p className="cart-card__session-duration">
              Session Duration: {sessionDuration} minutes
            </p> */}
          </div>
          <div className="cart-card__qty-price">
            <div className="quantity">
              <img
                src={`${process.env.NEXT_PUBLIC_ALL_ASSETS_API}binIcon.svg`}
                alt=""
                onClick={() => {
                  dispatch(removeQtyFromCart(courseId));
                  // dispatch(setPromoCodeResponse({}));
                  dispatch(
                    setPromoCodeUiStates({
                      promoInputClassName: "cart-preview__promo-code--input",
                      promoTooltipClassName:
                        "cart-preview__promo-code--validation-tooltip",
                      promoTooltipText: "",
                    })
                  );
                }}
              />
              <span>{courseQty}</span>
              <img
                src={`${process.env.NEXT_PUBLIC_ALL_ASSETS_API}plusIcon.svg`}
                alt=""
                onClick={() => {
                  dispatch(addQtyToCart(courseId));
                  // dispatch(setPromoCodeResponse({}));
                  dispatch(
                    setPromoCodeUiStates({
                      promoInputClassName: "cart-preview__promo-code--input",
                      promoTooltipClassName:
                        "cart-preview__promo-code--validation-tooltip",
                      promoTooltipText: "",
                    })
                  );
                }}
              />
            </div>
            <span className="price">
              {coursePrice !== coursePriceOld ? (
                <span className="slashed-price">
                  {parseCurrencySymbol(courseCurrency, true)}
                </span>
              ) : null}
              <span>{parseCurrencySymbol(courseCurrency)}</span>
            </span>
          </div>
        </div>
      ) : null}
      {version === "--no-price-qty" ? (
        <div className="cart-card cart-card--large cart-card--no-price-qty">
          <div className="cart-card--large-left">
            <h1 className="cart-card__name">{courseName}</h1>
            <h2 className="cart-card__category">Category: {courseCategory}</h2>
            <p className="cart-card__course-duration">
              Course Duration: {courseDuration} Weeks (One session/week)
            </p>
            {/* <p className="cart-card__session-duration">
              Session Duration: {sessionDuration} minutes
            </p> */}
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default CartCard;
