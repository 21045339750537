import React, { useState, useEffect } from "react";
import Link from "next/link";
import moengageEvent from "../helpers/MoengageEventTracking";
import {
  bookTrialClickAttributes,
  visitLiveAttributes,
  footertAttributes,
} from "../helpers/MoengageAttributeCreators";
import { leadsquaredActivityDispatcher } from "../helpers/leadsquaredActivityDispatcher";
import {
  bookTrialClickActivity,
  footerEventActivity,
} from "../helpers/leadsquaredAttributeCreators";
import { useDispatch, useSelector } from "react-redux";
import { setContext } from "../store/actions/bookingActions";
import { getTeacherProfiles } from "../helpers/trialHelpers";

function Footer() {
  const dispatch = useDispatch();
  const [responsiveMode, setResponsiveMode] = useState(false);
  useEffect(() => {
    if (window.innerWidth < 1251) {
      setResponsiveMode(true);
    }
  }, []);
  const authToken = useSelector((state) => state.auth.authToken);
  const userDetails = useSelector((state) => state.auth.userDetails);
  const teacherProfiles = useSelector((state) => state.auth.teacherProfiles);
  const isNextEduUser = useSelector(
    (state) => state.dashboard_session.isNextEduUser
  );
  // ! can show book-a-trial
  const canShowTrial = () => {
    if (window) {
      let route = window.location.pathname;
      let search = window.location.search;
      if (route.includes("/ted-ed-club")) return false;
      if (route.includes("/paid-workshop")) return false;
      if (route.includes("/payment-successful")) return false;
      if (route.includes("/checkout")) return false;
      if (route.includes("/live")) return false;
      if (route.includes("/oktoberfest")) return false;
      if (search?.includes("b=t")) return false;
      if (isNextEduUser) return false;
    }

    return true;
  };

  const showTeacherDashboardURL = () => {
    if (teacherProfiles.length === 0) return false;
    return true;
  };

  return (
    <div className="spark-footer__wrapper">
      <img
        src={`${process.env.NEXT_PUBLIC_ALL_ASSETS_API}confettiFaded.svg`}
        alt=""
        className="confetti confetti-1"
      />
      <img
        src={`${process.env.NEXT_PUBLIC_ALL_ASSETS_API}confettiFaded.svg`}
        alt=""
        className="confetti confetti-2"
      />
      <div className="spark-footer">
        <div className="spark-footer__left">
          <div className="spark-footer__left--top">
            <img
              src={`${process.env.NEXT_PUBLIC_ALL_ASSETS_API}sparkLogoWhite.png`}
              alt=""
              className="spark-footer__left--top-logo"
            />
            <p className="spark-footer__left--top-content">
              Online extracurriculars for your kids. Why online? Easy. Great
              teachers, superb lessons, children interacting with other great
              minds and from the comfort of your home. Our kids create in our
              classes. They draw, express, enjoy, communicate, sing; with almost
              no parent intervention.
            </p>
          </div>
          <div className="spark-footer__left--bottom">
            <h1 className="spark-footer__left--bottom-header">Get Started</h1>
            {canShowTrial() && (
              <Link href="/book-a-trial">
                <a
                  onClick={() => {
                    dispatch(setContext("trial"));
                    moengageEvent(
                      "Book_Trial_Click",
                      bookTrialClickAttributes(
                        "Book a FREE trial",
                        "/book-a-trial",
                        1,
                        "On the footer",
                        ""
                      )
                    );
                    if (authToken.length > 0) {
                      leadsquaredActivityDispatcher(
                        bookTrialClickActivity(
                          "Book a FREE trial",
                          "/book-a-trial",
                          1,
                          "On the footer",
                          window.location.pathname
                        ),
                        authToken,
                        userDetails.id
                      );
                    }
                  }}
                >
                  Book a FREE Trial
                </a>
              </Link>
            )}

            {/* <Link href="/courses/winter-camp">
              <a
                onClick={() => {
                  moengageEvent(
                    "Footer_Event",
                    footertAttributes("", "", "/courses/winter-camp")
                  );
                }}
              >
                Winter Wonderfest
              </a>
            </Link> */}

            {
              <Link href="/dashboard/all-classes">
                <a
                  onClick={() =>
                    moengageEvent("Visit_Live", visitLiveAttributes(4))
                  }
                >
                  Join Trial Class
                </a>
              </Link>
            }

            {/* {showTeacherDashboardURL() && (
              <Link href="/teacher-dashboard">
                <a
                  onClick={() =>
                    moengageEvent("Visit_Live", visitLiveAttributes(5))
                  }
                >
                  Teacher Dashboard
                </a>
              </Link>
            )} */}
            {/* <Link href="/workshops">
              <a>Workshops</a>
            </Link> */}
            <Link href="/blog">
              <a
              // onClick={() => {
              //   moengageEvent(
              //     "Footer_Event",
              //     footertAttributes("", "", "/blog")
              //   );
              // }}
              >
                Blog
              </a>
            </Link>

            {/* <Link>Summer Fest</Link> */}
          </div>
        </div>
        <div className="spark-footer__right spark-footer__sitemap">
          {/* <div className="spark-footer__right--column"></div> */}
          <div className="spark-footer__right--column">
            <h1 className="spark-footer__right--column-item-header">Company</h1>
            <h3 className="spark-footer__right--column-item">
              <Link href="/about-us">
                <a
                // onClick={() => {
                //   moengageEvent(
                //     "Footer_Event",
                //     footertAttributes("", "", "/about-us")
                //   );
                // }}
                >
                  About us
                </a>
              </Link>
            </h3>
            <h3 className="spark-footer__right--column-item">
              <Link href="/teach">
                <a
                // onClick={() => {
                //   moengageEvent(
                //     "Footer_Event",
                //     footertAttributes("", "", "/teach")
                //   );
                // }}
                >
                  Teach at Spark Studio
                </a>
              </Link>
            </h3>
            <h3 className="spark-footer__right--column-item">
              <Link href="/faq">
                <a
                // onClick={() => {
                //   moengageEvent(
                //     "Footer_Event",
                //     footertAttributes("", "", "/faq")
                //   );
                // }}
                >
                  FAQs
                </a>
              </Link>
            </h3>
            {/* <h3 className="spark-footer__right--column-item">
              <Link to="/about-us">Careers</Link>
            </h3> */}
            {/* <h3 className="spark-footer__right--column-item">
              <Link to="/about-us">Press Releases</Link>
            </h3> */}
            <h3 className="spark-footer__right--column-item">
              <Link href="/privacy-policy">
                <a
                // onClick={() => {
                //   moengageEvent(
                //     "Footer_Event",
                //     footertAttributes("", "", "/privacy-policy")
                //   );
                // }}
                >
                  Privacy Policy
                </a>
              </Link>
            </h3>
            <h3 className="spark-footer__right--column-item">
              <Link href="/terms-and-conditions">
                <a
                // onClick={() => {
                //   moengageEvent(
                //     "Footer_Event",
                //     footertAttributes("", "", "/terms-and-conditions")
                //   );
                // }}
                >
                  Terms and Conditions
                </a>
              </Link>
            </h3>
            <h3 className="spark-footer__right--column-item">
              <Link href="/affiliate">
                <a target="_blank">Affiliate Program</a>
              </Link>
            </h3>
            <h3 className="spark-footer__right--column-item">
              <Link href="/terms-and-conditions">
                <a
                  href={`${process.env.NEXT_PUBLIC_PURCHASE_ASSETS_API}SparkStudioRefundPolicy.pdf`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Refund Policy
                </a>
              </Link>
            </h3>
            {/* <h3 className="spark-footer__right--column-item">
              <Link to="/about-us">Refund Policy</Link>
            </h3> */}
          </div>
          <div className="spark-footer__right--column">
            <h1 className="spark-footer__right--column-item-header">Courses</h1>
            <h3 className="spark-footer__right--column-item">
              <Link href="/courses">
                <a
                // onClick={() => {
                //   moengageEvent(
                //     "Footer_Event",
                //     footertAttributes("", "", "/courses")
                //   );
                // }}
                >
                  All Courses
                </a>
              </Link>
            </h3>
            <h3 className="spark-footer__right--column-item">
              <Link href="/courses/teded-club">
                <a
                // onClick={() => {
                //   moengageEvent(
                //     "Footer_Event",
                //     footertAttributes("", "", "/courses/teded-club")
                //   );
                // }}
                >
                  TedEd Club
                </a>
              </Link>
            </h3>
            <h3 className="spark-footer__right--column-item">
              <Link href="/courses/art">
                <a
                // onClick={() => {
                //   moengageEvent(
                //     "Footer_Event",
                //     footertAttributes("", "", "/courses/art")
                //   );
                // }}
                >
                  Art
                </a>
              </Link>
            </h3>
            <h3 className="spark-footer__right--column-item">
              <Link href="/courses/western-vocals">
                <a
                // onClick={() => {
                //   moengageEvent(
                //     "Footer_Event",
                //     footertAttributes("", "", "/courses/western-vocals")
                //   );
                // }}
                >
                  Western Vocals
                </a>
              </Link>
            </h3>
            <h3 className="spark-footer__right--column-item">
              <Link href="/courses/stop-motion-animation">
                <a
                // onClick={() => {
                //   moengageEvent(
                //     "Footer_Event",
                //     footertAttributes(
                //       "",
                //       "",
                //       "/courses/stop-motion-animation"
                //     )
                //   );
                // }}
                >
                  Animation
                </a>
              </Link>
            </h3>
            <h3 className="spark-footer__right--column-item">
              <Link href="/courses/public-speaking">
                <a
                // onClick={() => {
                //   moengageEvent(
                //     "Footer_Event",
                //     footertAttributes("", "", "/courses/public-speaking")
                //   );
                // }}
                >
                  Public Speaking
                </a>
              </Link>
            </h3>
            <h3 className="spark-footer__right--column-item">
              <Link href="/courses/art-of-storytelling">
                <a
                // onClick={() => {
                //   moengageEvent(
                //     "Footer_Event",
                //     footertAttributes("", "", "/courses/art-of-storytelling")
                //   );
                // }}
                >
                  Storytelling
                </a>
              </Link>
            </h3>
            <h3 className="spark-footer__right--column-item">
              <Link href="/courses/photography">
                <a
                // onClick={() => {
                //   moengageEvent(
                //     "Footer_Event",
                //     footertAttributes("", "", "/courses/photography")
                //   );
                // }}
                >
                  Photography
                </a>
              </Link>
            </h3>
            <h3 className="spark-footer__right--column-item">
              <Link href="/courses/guitar">
                <a
                // onClick={() => {
                //   moengageEvent(
                //     "Footer_Event",
                //     footertAttributes("", "", "/courses/guitar")
                //   );
                // }}
                >
                  Guitar
                </a>
              </Link>
            </h3>
            <h3 className="spark-footer__right--column-item">
              <Link href="/courses/keyboard">
                <a
                // onClick={() => {
                //   moengageEvent(
                //     "Footer_Event",
                //     footertAttributes("", "", "/courses/keyboard")
                //   );
                // }}
                >
                  Keyboard
                </a>
              </Link>
            </h3>
            <h3 className="spark-footer__right--column-item">
              <Link href="/courses/spoken-english/">
                <a
                // onClick={() => {
                //   moengageEvent(
                //     "Footer_Event",
                //     footertAttributes("", "", "/courses/spoken-english")
                //   );
                // }}
                >
                  Spoken English
                </a>
              </Link>
            </h3>
            {/* <h3 className="spark-footer__right--column-item">
              <Link to="/courses/debate">Debate</Link>
            </h3> */}
          </div>
        </div>
      </div>
      {responsiveMode ? (
        <div className="spark-footer__social">
          <div>
            <span className="contact">
              <Link href="tel:+916363701578">
                <a
                  className="call"
                  onClick={() => {
                    if (authToken.length > 0) {
                      leadsquaredActivityDispatcher(
                        footerEventActivity("phone number"),
                        authToken,
                        userDetails.id
                      );
                    }
                  }}
                >
                  Call us : +91 63637 01578{" "}
                </a>
              </Link>

              <span className="separator"></span>
              <Link href="https://bit.ly/whatsappsparkst">
                <a
                  className="whatsapp"
                  onClick={() => {
                    if (authToken.length > 0) {
                      leadsquaredActivityDispatcher(
                        footerEventActivity("whatsapp number"),
                        authToken,
                        userDetails.id
                      );
                    }
                  }}
                >
                  WhatsApp us : +91 89517 62016
                </a>
              </Link>
            </span>
            {/* <a href="tel:+916363701578" className="call">
              Call +91 63637 01578{" "}
            </a> */}
          </div>
          <p>Copyright 2020 © Imagineer Technologies Pvt. Ltd.</p>
          <div className="social-icons">
            <Link href="https://www.facebook.com/TeamSparkStudio">
              <a target="_blank" rel="noopener noreferrer">
                <img
                  src={`${process.env.NEXT_PUBLIC_ALL_ASSETS_API}facebookDark.svg`}
                  alt=""
                />
              </a>
            </Link>

            <Link href="https://www.instagram.com/sparkstudio_co/">
              <a target="_blank" rel="noopener noreferrer">
                <img
                  src={`${process.env.NEXT_PUBLIC_ALL_ASSETS_API}instagramDark.svg`}
                  alt=""
                />
              </a>
            </Link>

            <Link href="https://bit.ly/whatsappsparkst">
              <a target="_blank" rel="noopener noreferrer">
                <img
                  src={`${process.env.NEXT_PUBLIC_ALL_ASSETS_API}whatsappDark.svg`}
                  alt=""
                />
              </a>
            </Link>
          </div>
        </div>
      ) : (
        <div className="spark-footer__social">
          <div className="social-icons">
            <Link href="https://www.facebook.com/TeamSparkStudio">
              <a target="_blank" rel="noopener noreferrer">
                <img
                  src={`${process.env.NEXT_PUBLIC_ALL_ASSETS_API}facebookDark.svg`}
                  alt=""
                />
              </a>
            </Link>

            <Link href="https://www.instagram.com/sparkstudio_co/">
              <a target="_blank" rel="noopener noreferrer">
                <img
                  src={`${process.env.NEXT_PUBLIC_ALL_ASSETS_API}instagramDark.svg`}
                  alt=""
                />
              </a>
            </Link>
            <Link href="https://bit.ly/whatsappsparkst">
              <a target="_blank" rel="noopener noreferrer">
                <img
                  src={`${process.env.NEXT_PUBLIC_ALL_ASSETS_API}whatsappDark.svg`}
                  alt=""
                />
              </a>
            </Link>
          </div>
          <span className="contact">
            <Link href="tel:+916363701578">
              <a
                className="call"
                onClick={() => {
                  if (authToken.length > 0) {
                    leadsquaredActivityDispatcher(
                      footerEventActivity("phone number"),
                      authToken,
                      userDetails.id
                    );
                  }
                }}
              >
                Call us : +91 63637 01578{" "}
              </a>
            </Link>

            <span className="separator"></span>
            <Link href="https://bit.ly/whatsappsparkst">
              <a
                className="whatsapp"
                onClick={() => {
                  if (authToken.length > 0) {
                    leadsquaredActivityDispatcher(
                      footerEventActivity("whatsapp number"),
                      authToken,
                      userDetails.id
                    );
                  }
                }}
              >
                WhatsApp us : +91 89517 62016
              </a>
            </Link>
          </span>
          <p>Copyright 2020 © Imagineer Technologies Pvt. Ltd.</p>
        </div>
      )}
    </div>
  );
}

export default Footer;
