import Bowser from "bowser";
let userAgent;
const joinClassActivityFBPayload = (
  // email,
  // phone,
  // fullName,
  externalId,
  // country,
  // state,
  // city,
  classId,
  courseId,
  classType,
  classDate,
  sessionNumber,
  courseName,
  teacherName
) => {
  // userAgent = Bowser.parse(window.navigator.userAgent);
  return {
    name: "Join_Class",
    time: Math.floor(new Date() / 1000),
    source: "website",
    fb_click_id: "",
    client_user_agent: window.navigator.userAgent,
    data: {
      class_id: classId,
      course_id: courseId,
      class_type: classType,
      class_date: classDate,
      session_number: sessionNumber,
      course_name: courseName,
      teacher_name: teacherName,
      // device: `${userAgent?.os.name} | ${userAgent?.platform.type} | ${userAgent?.browser.name}`,
    },
    user: {
      // email: email,
      // phone_no: phone,
      // full_name: fullName,
      id: externalId,
      // address: {
      //   country: country,
      //   state: state,
      //   city: city,
      // },
    },
  };
};

const addToCartActivityFBPayload = (
  externalId,
  courseCardId,
  courseCardName,
  coursePrice
) => {
  return {
    name: "Add_To_Cart",
    time: Math.floor(new Date() / 1000),
    source: "website",
    fb_click_id: "",
    client_user_agent: window.navigator.userAgent,
    data: {
      course_id: courseCardId,
      course_title: courseCardName,
      course_price: coursePrice,
    },
    user: {
      id: externalId,
    },
  };
};

const referralActivityFBPayload = (
  externalId,
  stageId,
  stageTitle,
  activity,
  personalReferralCode,
  buttonTitle = ""
) => {
  return {
    name: "Referral",
    time: Math.floor(new Date() / 1000),
    source: "website",
    fb_click_id: "",
    client_user_agent: window.navigator.userAgent,
    data: {
      stage_id: stageId,
      stage_title: stageTitle,
      activity: activity,
      personal_referral_code: personalReferralCode,
      button_title: buttonTitle,
    },
    user: {
      id: externalId,
    },
  };
};

const pageViewActivityFBPayload = (externalId, source) => {
  return {
    name: "Page_View",
    time: Math.floor(new Date() / 1000),
    source: "website",
    fb_click_id: "",
    client_user_agent: window.navigator.userAgent,
    data: {
      sourcePage: source,
    },
    user: {
      id: externalId,
    },
  };
};
const playVideoActivityFBPayload = (externalId, videoDetails) => {
  return {
    name: "Page_View",
    time: Math.floor(new Date() / 1000),
    source: "website",
    fb_click_id: "",
    client_user_agent: window.navigator.userAgent,
    data: {
      videoClicked: videoDetails,
    },
    user: {
      id: externalId,
    },
  };
};
const checkoutActivityFBPayload = (
  externalId,
  numberOfItems,
  coursesChosen,
  promoCode
) => {
  return {
    name: "Checkout",
    time: Math.floor(new Date() / 1000),
    source: "website",
    fb_click_id: "",
    client_user_agent: window.navigator.userAgent,
    data: {
      numberOfItems,
      coursesChosen,
      promoCode,
    },
    user: {
      id: externalId,
    },
  };
};
export {
  joinClassActivityFBPayload,
  addToCartActivityFBPayload,
  referralActivityFBPayload,
  pageViewActivityFBPayload,
  playVideoActivityFBPayload,
  checkoutActivityFBPayload,
};
